.ql-hidden {
  display: none;
}

.ql-bubble.ql-disabled > .ql-clipboard {
  display: none;
}

.ql-bubble.ql-disabled > .ql-editor {
  padding: initial;
}

.ql-container.ql-bubble.ql-disabled {
  font-family: inherit;
  font-size: inherit;
}

.ql-bubble.ql-disabled .ql-editor > * {
  cursor: inherit;
}

.ql-bubble .ql-editor h1 {
  line-height: 1.25;
  font-size: 2.5rem;
  font-weight: normal;
  color: black;
}

.ql-bubble .ql-editor h2 {
  line-height: 1.33;
  font-size: 2rem;
  font-weight: normal;
  color: black;
}

.ql-bubble .ql-editor h3 {
  line-height: 1.5;
  font-size: 1.5rem;
  font-weight: normal;
  color: black;
}

.ql-bubble .ql-editor h4 {
  line-height: 1.5;
  font-size: 1.25rem;
  font-weight: 500;
  color: black;
}

.ql-bubble .ql-editor h5 {
  line-height: 1.5;
  font-size: 1.125rem;
  font-weight: 500;
  color: black;
}

@media (min-width: 400px) {
  .ql-bubble .ql-editor h1 {
    font-size: 3.5rem;
  }

  .ql-bubble .ql-editor h2 {
    font-size: 2.5rem;
  }

  .ql-bubble .ql-editor h3 {
    font-size: 1.625rem;
  }

  .ql-bubble .ql-editor h4 {
    font-size: 1.125rem;
  }

  .ql-bubble .ql-editor h5 {
    font-size: 1.1875rem;
  }
}

@media (min-width: 900px) {
  .ql-bubble .ql-editor h1 {
    font-size: 4rem;
  }

  .ql-bubble .ql-editor h2 {
    font-size: 2.75rem;
  }

  .ql-bubble .ql-editor h3 {
    font-size: 1.875rem;
  }

  .ql-bubble .ql-editor h4 {
    font-size: 1.375rem;
  }

  .ql-bubble .ql-editor h5 {
    font-size: 1.25rem;
  }
}

@media (min-width: 1200px) {
  .ql-bubble .ql-editor h1 {
    font-size: 4.625rem;
  }

  .ql-bubble .ql-editor h2 {
    font-size: 3rem;
  }

  .ql-bubble .ql-editor h3 {
    font-size: 2rem;
  }

  .ql-bubble .ql-editor h4 {
    font-size: 1.375rem;
  }

  .ql-bubble .ql-editor h5 {
    font-size: 1.25rem;
  }
}